.app {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
}
/* Header */
.App-Logo {
  height: 10vmin;
  pointer-events: none;
}
.store-logo {
  height: 10vmin;
}
/* Language Selector */
.lang-selector {
  width: 400px;
}

.box {
  display: flex;
  margin: 0 auto 0 auto;
  width: '100%';
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.full-width{
  display: inline-block;
  width: '100%';
}
.txt{
  text-align: justify;
}
.txt-header{
  font-style: 3.5vw;
  text-align: center;
}
.top-spacing{
  margin-top: 100px;
}
.top-spacing-section{
  margin-top: 25px;
}
.incline-oval {
  /* display: block;
  width: 200px;
  height: 180px; */
  /* border-radius: 50% 10% 30% 20% / 70% 60% 40% 40%; */
  /* -moz-border-radius: 50px/50px; */
  /* -webkit-border-radius: 50px 50px; */
  /* border-radius: 50px/50px; */
  /* border: solid 21px #009444; */
  /* width: 50px; */
  /* height: 50px; */
  border-left: 53px solid #ffffff;
  border-top: 2px solid #ffffff;
  border-right: 76px solid #ffffff;
  border-bottom: 100px solid #ffffff;
}
.white-hollow{
  border-radius: 100px;
  /* border-radius: 50% 10% 30% 20% / 70% 60% 40% 40%; */
  border: solid 21px #ffffff;

}
.mission-img{
  width: 110vmin;
  height: 60vmin;
  pointer-events: none;
}
.align-centre{
  align-self: center;
}
.descrition-logo{
  height: 15vmin;
  pointer-events: none;
}
.img-linesale-human{
  height: 40vmin;
  pointer-events: none;
  /* position: absolute; */
  /* right: 0; */
  /* z-index: 999; */
}
.img-mock-parvo{
  height: 50vmin;
  pointer-events: none;
}
.img-mock-mobile{
  height: 65vmin;
  pointer-events: none;
  z-index: 999;
}
.img-card-image{
  height: 52vmin;
  pointer-events: none;
  z-index: 999;
}