.btn-container {
    border-radius: 10;
    padding: 5;
}
.btn {
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 20px;
    color: #FFFFFF;
    transition: all 0.5s;
}
.btn:hover {
    background-color: #31c844 !important;
}
.btn:active {
    background-color: #31c844 !important;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}
.btn span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}
.btn span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}  
.btn:hover span {
    padding-right: 25px;
} 
.btn:hover span:after {
    opacity: 1;
    right: 0;
}