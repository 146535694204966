li {
}
.cool-link {
    display: inline-block;
    text-decoration: none;
}
.cool-link::after {
    content: '';
    display: block;
    width: 025%;
    height: 2vmin;
    border-radius: 20px;
    background: #ffd42d;
    transition: width 1s;
}
.cool-link:hover::after {
    width: 150%;
    transition: width 1s;
}
a:link {
    text-decoration: none;
}
  a:visited {
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
a:active {
    text-decoration: none;
}