.animate-origin-bottom{
    animation-duration: 2s;
    transform-origin: bottom;
    flex: auto;
    /* transition: 1s; */
}
.bounce{
    animation-name: bounce;
    animation-timing-function: ease;
}
@keyframes bounce {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-100px); }
    100% { transform: translateY(0); }
}